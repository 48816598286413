
.detail-index-top-right .live-detail-down-button {
  //width: 1.49rem;
  height: 0.5rem;
  background: #f1e8e8;
  border-radius: 0.25rem;
  text-align: center;
  line-height: 0.5rem;
  font-size: 0.16rem;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  color: #761f1e;
  display: inline-block;
  font-weight: 500;
  margin-left: 0.26rem;
}
//.live-detail-top-button{
//display: inline-block;
//color: #761F1E;
//position: absolute;
//bottom: 40px;
//}
